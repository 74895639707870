import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { BASE_URL } from '../../Api/Constants'
import DrawerNav from '../Navigation/DrawerNav'

export default function EditSubtopic() {
    const location = useLocation()
    // eslint-disable-next-line no-unused-vars
    const navigate = useNavigate()
    const searchParams = new URLSearchParams(location.search)
    const topicName = searchParams.get('topic')
    const subtopicName = searchParams.get('subtopic')
    const oldDescription = searchParams.get('description')
    const action = searchParams.get('action')
    const topicId = searchParams.get('id')
    const [newSubtopic, setNewSubtopic] = useState(subtopicName)
    const [newDesc, setNewDesc] = useState(oldDescription)
    const [newTopic, setNewTopic] = useState(topicName)
    useEffect(() => {}, [])

    const handleSaveChanges = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/admin/subtopic/${topicId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    name: newSubtopic,
                    description: newDesc.trim(),
                    topic: newTopic
                })
            })
            if (response.ok) {
                console.log('Subject modified successfully')
                setNewSubtopic('')
                navigate('/')
            } else {
                throw new Error('Failed to modify subject')
            }
        } catch (error) {
            console.error(error)
        }
    }

    const handleDeleteTopic = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/admin/subtopic/${topicId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            if (response.ok) {
                navigate(-1)
            } else {
                throw new Error('Failed to modify subject')
            }
        } catch (error) {
            console.error(error)
        }
    }

    const [topics, setTopics] = useState([])

    useEffect(() => {
        const fetchTopics = async () => {
            try {
                const response = await fetch(`${BASE_URL}/api/user/topics`)
                const data = await response.json()
                if (response.ok) {
                    console.log('Data : ', data)
                    setTopics(data.data.topicsList)
                } else {
                    console.error(data.message)
                }
            } catch (error) {
                console.error('Error:', error)
            }
        }

        fetchTopics()
    }, [])

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                <Box p={2}>
                    <Typography variant='h4' sx={{ marginY: 2 }}>
                        Edit Subtopic
                    </Typography>

                    {action === 'edit' && (
                        <>
                            <TextField label='Subtopic' value={newSubtopic} onChange={(event) => setNewSubtopic(event.target.value)} fullWidth variant='outlined' margin='normal' />
                            <FormControl fullWidth variant='outlined' margin='normal'>
                                <InputLabel id='select-topic-label'>Choose Topic</InputLabel>
                                <Select labelId='select-topic-label' id='select-topic' value={newTopic} onChange={(event) => setNewTopic(event.target.value)} label='Choose Topic'>
                                    {topics.map((topic, index) => (
                                        <MenuItem key={index} value={topic}>
                                            {topic}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <TextField label='Description' value={newDesc} onChange={(event) => setNewDesc(event.target.value)} fullWidth variant='outlined' margin='normal' />
                            <Button sx={{ mt: 2 }} variant='contained' color='primary' onClick={handleSaveChanges}>
                                Save Changes
                            </Button>
                        </>
                    )}
                    {action === 'delete' && (
                        <>
                            <TextField label='Subtopic' value={newSubtopic} onChange={(event) => setNewSubtopic(event.target.value)} fullWidth variant='outlined' margin='normal' disabled />
                            <TextField label='Topic' value={newTopic} onChange={(event) => setNewTopic(event.target.value)} fullWidth variant='outlined' margin='normal' disabled />
                            <Button sx={{ mt: 2 }} variant='contained' color='error' onClick={handleDeleteTopic}>
                                Delete Subtopic
                            </Button>
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    )
}
