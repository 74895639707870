import React, { useState, useEffect } from 'react'
import { Box, Button, TextField, Typography, Select, MenuItem, FormControl, InputLabel } from '@mui/material'
import DrawerNav from '../Navigation/DrawerNav'
import { BASE_URL } from '../../Api/Constants'
import { useNavigate } from 'react-router-dom'

export default function AddQuestion() {
    const [question, setQuestion] = useState('')
    const [topic, setTopic] = useState('')
    const [subtopic, setSubtopic] = useState('')
    const [id, setId] = useState('')
    const [optionA, setOptionA] = useState('')
    const [optionB, setOptionB] = useState('')
    const [optionC, setOptionC] = useState('')
    const [optionD, setOptionD] = useState('')
    const [correctOption, setCorrectOption] = useState('')
    const [explanation, setExplanation] = useState('')
    const [topics, setTopics] = useState([])
    const [subtopics, setSubtopics] = useState([])
    const navigate = useNavigate()

    const options = [
        { value: optionA, label: optionA },
        { value: optionB, label: optionB },
        { value: optionC, label: optionC },
        { value: optionD, label: optionD }
    ]

    useEffect(() => {
        const fetchTopics = async () => {
            try {
                const response = await fetch(`${BASE_URL}/api/admin/v1/subject`)
                const data = await response.json()
                if (response.ok) {
                    console.log('Data : ', data)
                    setTopics(data.data.subjectsList)
                } else {
                    console.error(data.message)
                }
            } catch (error) {
                console.error('Error:', error)
            }
        }

        fetchTopics()
    }, [])

    useEffect(() => {
        const fetchSubtopics = async () => {
            if (topic) {
                console.log('Topic : ', topic)
                try {
                    const response = await fetch(`${BASE_URL}/api/admin/v2/exams?subject=${topic}`)
                    const data = await response.json()
                    if (response.ok) {
                        console.log('Subtopics Data : ', data)
                        setSubtopics(data.data.examsList)
                    } else {
                        console.error(data.message)
                    }
                } catch (error) {
                    console.error('Error:', error)
                }
            }
        }

        fetchSubtopics()
    }, [topic])

    const handleSaveQuestion = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/admin/v1/question`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id,
                    question,
                    subject: topic,
                    exam: subtopic,
                    options: [optionA, optionB, optionC, optionD],
                    correctOption,
                    explanation
                })
            })

            const data = await response.json()

            if (response.ok) {
                setQuestion('')
                setTopic('')
                setSubtopic('')
                setOptionA('')
                setOptionB('')
                setOptionC('')
                setOptionD('')
                setCorrectOption('')
                setExplanation('')
                navigate('/')
            } else {
                console.error(data.message)
            }
        } catch (error) {
            console.error('Error:', error)
        }
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                <Box p={2}>
                    <Typography variant='h4' sx={{ my: 2, fontWeight: 'bolder', color: '#4b4848' }}>
                        Add New Question
                    </Typography>
                    <TextField label='ID' value={id} onChange={(event) => setId(event.target.value)} fullWidth variant='outlined' margin='normal' />
                    <TextField label='Question' multiline={true} value={question} onChange={(event) => setQuestion(event.target.value)} fullWidth variant='outlined' margin='normal' />
                    <FormControl fullWidth variant='outlined' margin='normal'>
                        <InputLabel id='select-topic-label'>Choose Subject</InputLabel>
                        <Select labelId='select-topic-label' id='select-topic' value={topic} onChange={(event) => setTopic(event.target.value)} label='Choose Section'>
                            {topics.length > 0 &&
                                topics.map((topic, index) => (
                                    <MenuItem key={index} value={topic.name}>
                                        {topic.name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth variant='outlined' margin='normal'>
                        <InputLabel id='select-topic-label'>Choose Exam</InputLabel>
                        <Select labelId='select-topic-label' id='select-topic' value={subtopic} onChange={(event) => setSubtopic(event.target.value)} label='Choose Exam'>
                            {subtopics.map((topic, index) => (
                                <MenuItem key={index} value={topic.exam}>
                                    {topic.exam}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField label='Option A' value={optionA} onChange={(event) => setOptionA(event.target.value)} fullWidth variant='outlined' margin='normal' sx={{ backgroundColor: correctOption === 'A' ? '#9cda7d' : 'inherit' }} />
                    <TextField label='Option B' value={optionB} onChange={(event) => setOptionB(event.target.value)} fullWidth variant='outlined' margin='normal' sx={{ backgroundColor: correctOption === 'B' ? '#9cda7d' : 'inherit' }} />
                    <TextField label='Option C' value={optionC} onChange={(event) => setOptionC(event.target.value)} fullWidth variant='outlined' margin='normal' sx={{ backgroundColor: correctOption === 'C' ? '#9cda7d' : 'inherit' }} />
                    <TextField label='Option D' value={optionD} onChange={(event) => setOptionD(event.target.value)} fullWidth variant='outlined' margin='normal' sx={{ backgroundColor: correctOption === 'D' ? '#9cda7d' : 'inherit' }} />

                    <FormControl fullWidth variant='outlined' margin='normal'>
                        <InputLabel id='select-correct-option-label'>Correct Option</InputLabel>
                        <Select labelId='select-correct-option-label' id='select-correct-option' value={correctOption} onChange={(event) => setCorrectOption(event.target.value)} label='Correct Option'>
                            {options.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <TextField label='Explanation' multiline={true} value={explanation} onChange={(event) => setExplanation(event.target.value)} fullWidth variant='outlined' margin='normal' />
                    <Box mt={2}>
                        <Button variant='contained' color='success' onClick={handleSaveQuestion}>
                            Add Question
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
