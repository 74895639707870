/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import DrawerNav from '../Navigation/DrawerNav';
import { Box, TextField, Typography, Checkbox, FormControlLabel } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL } from '../../Api/Constants';

const BulkEmailSender = () => {
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [recipients, setRecipients] = useState(''); // Comma-separated emails
  const [sendToAll, setSendToAll] = useState(false); // Checkbox state

  const handleSendEmails = async () => {
    const emailData = {
      subject,
      body,
      recipients: sendToAll ? 'ALL_USERS' : recipients.split(',').map(email => email.trim()), // Use a special key or keep it as an array
    };

    try {
      const response = await fetch(`${BASE_URL}/api/v1/mails/inform-users`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(emailData),
      });

      if (response.ok) {
        toast.success('Emails sent successfully!', {
          position: "bottom-left",
        });
      } else {
        toast.error('Failed to send emails.', {
          position: "bottom-left",
        });
      }
    } catch (error) {
      console.error('Error sending emails:', error);
      toast.error('An error occurred while sending emails.', {
        position: "bottom-left",
      });
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <DrawerNav />
      <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
        <ToastContainer />
        <Box className="max-w-2xl mx-auto p-5 bg-white rounded-lg">
          <Typography variant="h4" sx={{ mb: 4 }} className="text-center mb-6">
            Send Bulk Emails
          </Typography>
          <Box className="space-y-4">
            {!sendToAll && (
              <TextField
                label="Recipients (comma-separated emails)"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                value={recipients}
                onChange={(e) => setRecipients(e.target.value)}
                placeholder="example1@mail.com, example2@mail.com"
              />
            )}
            <FormControlLabel
              control={
                <Checkbox
                  checked={sendToAll}
                  onChange={(e) => setSendToAll(e.target.checked)}
                  color="primary"
                />
              }
              label="Send to all users"
            />
            <TextField
              label="Subject"
              variant="outlined"
              fullWidth
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              placeholder="Enter subject"
            />
            <TextField
              label="Body"
              variant="outlined"
              fullWidth
              multiline
              rows={6}
              value={body}
              onChange={(e) => setBody(e.target.value)}
              placeholder="Enter email body"
            />
            <div className="my-8 flex flex-col sm:flex-row space-y-2.5 sm:space-y-0 sm:space-x-2.5 items-center justify-center">
              <a className="w-96" onClick={handleSendEmails}>
                <button type="button"
                  className="flex items-center justify-center px-4 py-3 w-full shadow-sm font-medium rounded-full border text-white bg-[#279c75] focus:outline-none focus:ring-2 focus:ring-offset-2 transition duration-250 ease-in-out">
                  <span className="text-base">Send Emails</span>
                </button>
              </a>
            </div>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BulkEmailSender;
