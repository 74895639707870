/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { BASE_URL } from '../../Api/Constants'
import { Box } from '@mui/material'
import DrawerNav from '../Navigation/DrawerNav'

function ViewSectionTopics() {
    const navigate = useNavigate()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const topicName = searchParams.get('name')
    const sectionName = searchParams.get('section')

    const [topicsList, setTopicsList] = useState([])
    const [apiCalled, setApiCalled] = useState(false)

    const [showLoader, setShowLoader] = useState(true)

    const getTopicsList = async () => {
        try {
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            }

            await fetch(`${BASE_URL}/api/admin/subtopics/v2?section=${sectionName}&topic=${topicName}`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    console.log(data)
                    if (data.status === 200) {
                        setTopicsList(data.data.subtopicsList)
                        setApiCalled(true)
                    } else {
                        setOpenErrorAlert(true)
                    }
                })
                .catch((error) => {
                    console.log('Error : ', error)
                    setOpenErrorAlert(true)
                    setApiCalled(true)
                })
        } catch {
        } finally {
            setShowLoader(false)
        }
    }

    const sectionsList = ['General Studies', 'Civil Services Aptitude', 'Current Affairs', 'Past Year Questions']

    const [openSuccessAlert, setOpenSuccessAlert] = useState(false)
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSuccessAlert(false)
    }
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenErrorAlert(false)
    }
    
    const handleSubtopicClick = (text) => {
        navigate('/questions-list?topic=' + topicName + '&subtopic=' + text + '&section=' + sectionName)
    }

    useEffect(() => {
        if (!apiCalled) {
            getTopicsList()
        }
    })

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <DrawerNav />
                {topicsList.length > 0 && (
                    <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                        <div className='pb-48'>
                            <h1 className='text-xl font-bold pb-8'>
                                {topicName} Subtopics in {sectionName}
                            </h1>
                            <div className='grid md:grid-cols-4 gap-4'>
                                {topicsList.map((topic, index) => {
                                    return (
                                        <div className='flex justify-center items-center cursor-pointer' onClick={() => handleSubtopicClick(topic.subtopic)}>
                                            <div className='w-full max-w-sm rounded-lg shadow bg-gray-800 border-gray-700'>
                                                <div className='flex flex-col items-center pb-6'>
                                                    <img className='w-24 h-24 mb-3 mt-6 rounded-full shadow-lg' src='/vite.svg' alt='Bonnie image' />
                                                    <h5 className='mb-1 text-xl font-medium text-white'>{topic.subtopic}</h5>
                                                    <h5 className='mb-1 text-base text-white'>{topic.count} Questions </h5>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </Box>
                )}
            </Box>
        </>
    )
}

export default ViewSectionTopics
