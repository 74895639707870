/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { BASE_URL } from '../../Api/Constants'
import { showTimeInAgoFormat } from '../../Utils/Time'
import { Box, Button, Table, TableBody, TextField } from '@mui/material'
import DrawerNav from '../Navigation/DrawerNav'
import { styled } from '@mui/material/styles'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'

import { useLocation } from 'react-router-dom'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#262726',
        color: theme.palette.common.white,
        fontSize: 20
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16
    }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    '&:last-child td, &:last-child th': {
        border: 0
    }
}))

function useQuery() {
    return new URLSearchParams(useLocation().search)
}

function SubjectComponent() {
    const navigate = useNavigate()
    const { id } = useParams()
    const query = useQuery()
    const subjectName = query.get('name')
    const [apiCalled, setApiCalled] = useState(false)
    const [topicsData, setTopicsData] = useState([])

    const getTopicsList = async () => {
        try {
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            }

            await fetch(`${BASE_URL}/api/admin/v1/subject/${id}`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    console.log(data)
                    if (data.status === 200) {
                        setTopicsData(data.data.resourceData.topics)
                        setApiCalled(true)
                    } else {
                        setOpenErrorAlert(true)
                    }
                })
                .catch((error) => {
                    console.log('Error : ', error)
                    setOpenErrorAlert(true)
                    setApiCalled(true)
                })
        } catch {
        } finally {
            setApiCalled(true)
        }
    }

    useEffect(() => {
        if (!apiCalled) {
            getTopicsList()
        }
    }, [])

    const [openSuccessAlert, setOpenSuccessAlert] = useState(false)
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSuccessAlert(false)
    }
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenErrorAlert(false)
    }

    const navigateToRoute = () => {
        navigate(`/add-topic?subjectID=${id}&subjectName=${subjectName}`)
    }

    const handleTopicClick = (topicID, name) => {
        navigate(`/topic/${topicID}?name=${name}&subjectName=${subjectName}&subjectID=${id}`)
    }

    const handleResourceClick = (id, action, type, name, description, image) => {
        navigate(`/edit-resource?id=${id}&action=${action}&type=${type}&name=${name}&description=${description}&imageURL=${image}`)
    }

    const [searchQuery, setSearchQuery] = useState('')
    const [filteredTopics, setFilteredTopics] = useState([])

    useEffect(() => {
        if (searchQuery === '') {
            setFilteredTopics(topicsData)
        } else {
            setFilteredTopics(topicsData.filter((topic) => topic.name.toLowerCase().includes(searchQuery.toLowerCase()) || (topic.description && topic.description.toLowerCase().includes(searchQuery.toLowerCase()))))
        }
    }, [searchQuery, topicsData])

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <DrawerNav />
                <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                    <div className='pb-48'>
                        <div className='flex justify-between items-center p-4'>
                            <h1 className='text-4xl font-bold'>{subjectName} Topics</h1>
                            <div className='space-x-2'>
                                <TextField size='small' variant='outlined' placeholder='Search...' value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} sx={{ height: '40px', backgroundColor: 'white' }} />
                                <Button variant='contained' color='primary' onClick={() => navigateToRoute('add-topic')}>
                                    Create Topic
                                </Button>
                            </div>
                        </div>
                        <Table sx={{ width: '100%', marginBottom: 30, marginTop: 5 }} aria-label='customized table'>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell className='rounded-tl-lg' align='left'>
                                        S.No
                                    </StyledTableCell>
                                    <StyledTableCell align='left'>Topic</StyledTableCell>
                                    <StyledTableCell align='left'>Description</StyledTableCell>
                                    <StyledTableCell align='left'>Exams</StyledTableCell>
                                    <StyledTableCell align='left'>Updated</StyledTableCell>
                                    <StyledTableCell align='left'>Created</StyledTableCell>
                                    <StyledTableCell align='left'>Edit</StyledTableCell>
                                    <StyledTableCell align='left'>Delete</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredTopics.length > 0 &&
                                    filteredTopics.map((topic, index) => {
                                        return (
                                            <StyledTableRow key={topic._id} sx={{ border: '1px solid gray' }}>
                                                <StyledTableCell align='left'>{index + 1}</StyledTableCell>
                                                <StyledTableCell align='left' className='underline cursor-pointer' onClick={() => handleTopicClick(topic._id, topic.name)}>
                                                    {topic.name}
                                                </StyledTableCell>
                                                <StyledTableCell align='left'>{topic.description}</StyledTableCell>
                                                <StyledTableCell align='left'>{topic.exams ? topic.exams.length : 0}</StyledTableCell>
                                                <StyledTableCell align='left'>{showTimeInAgoFormat(topic.updatedAt)}</StyledTableCell>
                                                <StyledTableCell align='left'>{showTimeInAgoFormat(topic.createdAt)}</StyledTableCell>
                                                <StyledTableCell align='left'>
                                                    <Button variant='contained' color='primary' onClick={() => handleResourceClick(topic._id, 'edit', 'topic', topic.name, topic.description, topic.imageURL)} sx={{ mr: 2 }}>
                                                        Edit
                                                    </Button>
                                                </StyledTableCell>
                                                <StyledTableCell align='left'>
                                                    <Button variant='contained' color='error' onClick={() => handleResourceClick(topic._id, 'delete', 'topic', topic.name, topic.description, topic.imageURL)}>
                                                        Delete
                                                    </Button>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )
                                    })}
                            </TableBody>
                        </Table>
                    </div>
                </Box>
            </Box>
        </>
    )
}

export default SubjectComponent
