import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { BASE_URL } from '../../Api/Constants'
import { Box, Button } from '@mui/material'
import DrawerNav from '../Navigation/DrawerNav'

import { DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors, closestCorners } from '@dnd-kit/core'
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable'

import './ReorderQuestions.css'

import { Column } from './Column/Column'

import { useLocation } from 'react-router-dom'
import axios from 'axios'
// eslint-disable-next-line no-unused-vars

import { toast } from 'react-toastify'

function useQuery() {
    return new URLSearchParams(useLocation().search)
}

function ReorderQuestions() {
    const navigate = useNavigate()
    const { id } = useParams()
    const query = useQuery()
    const examName = query.get('name')
    const [apiCalled, setApiCalled] = useState(false)
    const [questionsList, setQuestionsList] = useState([])

    const [tasks, setTasks] = useState([])

    const serializeQuestionData = (questionArr) => {
        let newArray = []
        questionArr.forEach((eachQuestion, index) => {
            newArray.push({
                _id: eachQuestion._id,
                id: index + 1,
                title: `Q ${index + 1}. ${eachQuestion.question}`
            })
        })
        setTasks(newArray)
    }

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    )

    const getTaskPos = (id) => tasks.findIndex((task) => task.id === id)

    const handleDragEnd = (event) => {
        const { active, over } = event

        if (active.id === over.id) return

        setTasks((tasks) => {
            const originalPos = getTaskPos(active.id)
            const newPos = getTaskPos(over.id)

            return arrayMove(tasks, originalPos, newPos)
        })
    }

    const handleSubmit = async () => {
        try {
            const response = await axios.post(`${BASE_URL}/api/admin/v1/questions-reorder`, {
                examID: id,
                questions: tasks
            })
            if (response.data.status === 200) {
                getTopicsList()
                toast.success('Questions reordered successfully!', {
                    position: 'bottom-left'
                })
            }
            console.log('Question added successfully:', response.data)
        } catch (error) {
            console.error('Error adding question:', error)
        }
    }

    const getTopicsList = async () => {
        try {
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            }

            await fetch(`${BASE_URL}/api/admin/v1/exam/${id}`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    console.log(data)
                    if (data.status === 200) {
                        setQuestionsList(data.data.resourceData.questions)
                        serializeQuestionData(data.data.resourceData.questions)
                        setApiCalled(true)
                    }
                })
                .catch((error) => {
                    console.log('Error : ', error)
                    setApiCalled(true)
                })
        } catch {
        } finally {
            setApiCalled(true)
        }
    }

    useEffect(() => {
        if (!apiCalled) {
            getTopicsList()
        }
    }, [])

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <DrawerNav />
                <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                    <div className='pb-48 p-4'>
                        <div className='flex justify-between items-center'>
                            <h1 className='text-4xl font-bold'>Reorder {examName} </h1>
                            <div className='space-x-2'>
                            <Button variant='contained' color='info' onClick={() => navigate(-1)}>
                                    Return to Exam
                                </Button>
                                <Button variant='contained' color='success' onClick={() => handleSubmit()}>
                                    Save Order
                                </Button>
                            </div>
                        </div>
                        <div className='mt-4'>
                            <DndContext sensors={sensors} collisionDetection={closestCorners} onDragEnd={handleDragEnd}>
                                <Column id='toDo' tasks={tasks} />
                            </DndContext>
                        </div>
                    </div>
                </Box>
            </Box>
        </>
    )
}

export default ReorderQuestions
