import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';
import { BASE_URL } from '../../Api/Constants';
import DrawerNav from '../Navigation/DrawerNav';
import { showTimeInAgoFormat } from '../../Utils/Time';

const MailLogs = () => {
  const [logs, setLogs] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const fetchLogs = async (page) => {
    const response = await fetch(`${BASE_URL}/api/v1/mails/logs?page=${page}`);
    const data = await response.json();

    if (data.data.logs.length === 0) {
      setHasMore(false); // Stop further API calls
    } else {
      setLogs((prevLogs) => [...prevLogs, ...data.data.logs]);
    }
  };

  useEffect(() => {
    fetchLogs(page);
  }, [page]);

  const loadMoreLogs = () => {
    if (hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <DrawerNav />
      <Box sx={{ flexGrow: 1, p: 3 }}>
        <InfiniteScroll
          dataLength={logs.length}
          next={loadMoreLogs}
          hasMore={hasMore}
          endMessage={<p style={{ textAlign: 'center' }}>No more items</p>}
        >
          <TableContainer component={Paper}>
            <Table aria-label="log table">
              <TableHead>
                <TableRow>
                  <TableCell><strong>Email</strong></TableCell>
                  <TableCell><strong>Type</strong></TableCell>
                  <TableCell><strong>Sent At</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {logs.map((log, index) => (
                  <TableRow key={index}>
                    <TableCell>{log.email}</TableCell>
                    <TableCell>{log.type}</TableCell>
                    <TableCell>{showTimeInAgoFormat(log.sentAt)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </InfiniteScroll>
      </Box>
    </Box>
  );
};

export default MailLogs;
