import * as React from 'react'
import { useEffect } from 'react'
import { Grid, Snackbar, Stack, Table, TableBody } from '@mui/material'
import { Box } from '@mui/system'
import MuiAlert from '@mui/material/Alert'
import { useNavigate } from 'react-router-dom'
import { BASE_URL } from '../../Api/Constants'
import DrawerNav from '../Navigation/DrawerNav'
import { styled } from '@mui/material/styles'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Delete, Edit } from '@mui/icons-material'
import CustomThreeDots from '../Loader/CustomThreeDots'
import { useState } from 'react'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#262726',
        color: theme.palette.common.white,
        fontSize: 20
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16
    }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}))

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

const ViewSubtopics = () => {
    const [apiCalled, setApiCalled] = React.useState(false)
    const [showLoader, setShowLoader] = React.useState(true)

    let navigate = useNavigate()

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = React.useState(false)
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSuccessAlert(false)
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = React.useState(false)
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenErrorAlert(false)
    }
    const [subtopicsList, setSubtopicsList] = useState([])

    const getSubtopicsList = async () => {
        try {
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            }

            await fetch(`${BASE_URL}/api/admin/v1/exams`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    console.log(data)
                    if (data.status === 200) {
                        setSubtopicsList(data.data.examsList)
                        setApiCalled(true)
                    } else {
                        setOpenErrorAlert(true)
                    }
                })
                .catch((error) => {
                    console.log('Error : ', error)
                    setOpenErrorAlert(true)
                    setApiCalled(true)
                })
        } catch {
        } finally {
            setShowLoader(false)
            setApiCalled(true)
        }
    }

    useEffect(() => {
        if (!apiCalled) {
            getSubtopicsList()
        }
    }, [apiCalled])

    const handleModifyTopic = (id, subtopic, name, section, desc, action) => {
        // navigate('/edit-subtopic?id=' + id + '&action=' + action + '&subtopic=' + subtopic + '&topic=' + name + '&section=' + section + '&description=' + desc)
    }

    const getColorBySection = (sectionText) => {
        const colors = ['#ac6933', '#2e95af', '#394ec9', '#4caf50']
        const randomIndex = Math.floor(Math.random() * colors.length)
        return colors[randomIndex]
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            {showLoader && (
                <div className='flex items-center justify-center m-32'>
                    <CustomThreeDots />
                </div>
            )}
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                {subtopicsList.length > 0 && (
                    <>
                        <Box sx={{ flexGrow: 2 }}>
                            <Grid align='center' justify='center' spacing={2}>
                                <Grid item lg={4} md={6} sm={12} className=''>
                                    <h1 className='text-4xl font-bold my-5 text-gray-700'> Subtopics </h1>
                                </Grid>
                                <Box sx={{ flexGrow: 2 }}>
                                    <Grid align='center' justify='center' sx={{ margin: 1 }}>
                                        <>
                                            <Table sx={{ width: '90%', marginTop: 4, marginBottom: 30 }} aria-label='customized table'>
                                                <TableHead>
                                                    <TableRow>
                                                        <StyledTableCell className='rounded-tl-lg' align='left'>
                                                            S. No
                                                        </StyledTableCell>
                                                        <StyledTableCell align='left'>Exam</StyledTableCell>
                                                        <StyledTableCell align='left'>Subject</StyledTableCell>
                                                        <StyledTableCell align='left'>Delete</StyledTableCell>
                                                        <StyledTableCell className='rounded-tr-lg' align='left'>
                                                            Edit
                                                        </StyledTableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {subtopicsList.length > 0 &&
                                                        subtopicsList.map((data, index) => {
                                                            return (
                                                                <StyledTableRow key={data._id} sx={{ border: '1px solid gray' }}>
                                                                    <StyledTableCell align='left'>{index + 1}</StyledTableCell>
                                                                    <StyledTableCell align='left' sx={{ color: getColorBySection(data.name), fontWeight: 'bolder' }}>
                                                                        {data.exam}
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align='left'>{data.subject}</StyledTableCell>
                                                                    <StyledTableCell align='left' onClick={() => handleModifyTopic(data._id, data.name, data.topic, data.section, data.description, 'delete')}>
                                                                        <Delete sx={{ cursor: 'pointer', color: '#eb4e4e' }} />
                                                                    </StyledTableCell>
                                                                    <StyledTableCell align='left' onClick={() => handleModifyTopic(data._id, data.name, data.topic, data.section, data.description, 'edit')}>
                                                                        <Edit sx={{ cursor: 'pointer', color: '#256f8d' }} />
                                                                    </StyledTableCell>
                                                                </StyledTableRow>
                                                            )
                                                        })}
                                                </TableBody>
                                            </Table>
                                        </>
                                    </Grid>
                                </Box>
                            </Grid>

                            <Stack spacing={2} sx={{ width: '100%' }}>
                                <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                                    <Alert onClose={closeSuccessAlert} severity='success' sx={{ width: '100%' }}>
                                        Success!!!
                                    </Alert>
                                </Snackbar>
                            </Stack>

                            <Stack spacing={2} sx={{ width: '100%' }}>
                                <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                                    <Alert onClose={closeErrorAlert} severity='error' sx={{ width: '100%' }}>
                                        Error!!!
                                    </Alert>
                                </Snackbar>
                            </Stack>
                        </Box>
                    </>
                )}
            </Box>
        </Box>
    )
}

export default ViewSubtopics
