import React, { useState, useEffect } from 'react';
import { Fab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Modal, TextField, Button, Box, IconButton, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import axios from 'axios';
import { BASE_URL } from '../../Api/Constants';
import DrawerNav from '../Navigation/DrawerNav';
import { capitalizeUnderscoreStringWords } from '../../Utils/String';

const StaticDataTable = () => {
    const [data, setData] = useState({});
    const [open, setOpen] = useState(false);
    const [editKey, setEditKey] = useState('');
    const [key, setKey] = useState('');
    const [value, setValue] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Fetch initial data
        axios.get(`${BASE_URL}/api/v1/static/data`)
            .then(response => {
                const filteredData = filterData(response.data.data.staticData);
                setData(filteredData);
                setLoading(false);
            })
            .catch(error => {
                console.error('There was an error fetching the data!', error);
                setLoading(false);
            });
    }, []);

    const filterData = (data) => {
        const excludedKeys = ['_id', 'type', '__v', 'createdAt', 'updatedAt'];
        const filteredData = Object.keys(data)
            .filter(key => !excludedKeys.includes(key))
            .reduce((obj, key) => {
                obj[key] = data[key];
                return obj;
            }, {});
        return filteredData;
    };

    const handleOpen = () => {
        setKey('');
        setValue('');
        setEditKey('');
        setOpen(true);
    };

    const handleEditOpen = (key, value) => {
        setKey(key);
        setValue(value);
        setEditKey(key);
        setOpen(true);
    };

    const handleClose = () => setOpen(false);

    const handleSubmit = () => {
        const apiURL = editKey ? `${BASE_URL}/api/v1/static/data/${editKey}` : `${BASE_URL}/api/v1/static/data`;
        const method = editKey ? 'put' : 'post';

        axios[method](apiURL, { key, value })
            .then(response => {
                setData(prevData => ({
                    ...prevData,
                    [key]: value
                }));
                handleClose();
            })
            .catch(error => {
                console.error('There was an error saving the data!', error);
            });
    };

    const handleDelete = (key) => {
        axios.delete(`${BASE_URL}/api/v1/static/data/${key}`)
            .then(response => {
                setData(prevData => {
                    const newData = { ...prevData };
                    delete newData[key];
                    return newData;
                });
            })
            .catch(error => {
                console.error('There was an error deleting the data!', error);
            });
    };

    return (
        <div>
            <Box sx={{ display: 'flex' }}>
                <DrawerNav />
                <Box sx={{ flexGrow: 1, p: 3 }}>
                    <h1 className='text-2xl font-bold my-4'>Static Data</h1>

                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Key</TableCell>
                                    <TableCell>Value</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.entries(data).map(([key, value]) => (
                                    <TableRow key={key}>
                                        <TableCell>{capitalizeUnderscoreStringWords(key)}</TableCell>
                                        <TableCell>{value}</TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => handleEditOpen(key, value)}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton onClick={() => handleDelete(key)} color="error">
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Fab color="primary" aria-label="add" onClick={handleOpen} style={{ position: 'fixed', bottom: '16px', right: '16px' }}>
                        <AddIcon />
                    </Fab>

                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="add-key-value-modal"
                        aria-describedby="add-key-value-modal-description"
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: 400,
                                bgcolor: 'background.paper',
                                boxShadow: 24,
                                p: 4,
                                borderRadius: '20px'
                            }}
                        >
                            <h2 className='text-2xl font-bold' id="add-key-value-modal">{editKey ? 'Edit' : 'Add'} Key-Value Pair</h2>
                            <TextField
                                fullWidth
                                label="Key"
                                value={key}
                                onChange={(e) => setKey(e.target.value)}
                                margin="normal"
                                disabled={!!editKey} // Disable the key field during editing
                            />
                            <TextField
                                fullWidth
                                label="Value"
                                value={value}
                                onChange={(e) => setValue(e.target.value)}
                                margin="normal"
                            />
                            <Button variant="contained" color="primary" onClick={handleSubmit} style={{ marginTop: '16px' }}>
                                Save
                            </Button>
                        </Box>
                    </Modal>
                </Box>
            </Box>
        </div>
    );
};

export default StaticDataTable;
