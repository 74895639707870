export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export function renderQuestionText(text) {
    try {
        let formattedText = text.replace(/\n/g, '<br />')
        formattedText = formattedText
            .replace(/-\*([^*]+)\*-/g, '<u>$1</u>') // -*text*- for underline
            .replace(/\*\*([^*]+)\*\*/g, '<b>$1</b>') // **text** for bold
            .replace(/\*([^*]+)\*/g, '<i>$1</i>') // *text* for italic

        return <div dangerouslySetInnerHTML={{ __html: formattedText }} />
    } catch {
        return <div dangerouslySetInnerHTML={{ __html: text }} />
    }

}

export function renderTextV2(text) {
    let formattedText = text.replace(/\n/g, '<br />')
    formattedText = formattedText
        .replace(/-\*([^*]+)\*-/g, '<u>$1</u>') // -*text*- for underline
        .replace(/\*\*([^*]+)\*\*/g, '<b>$1</b>') // **text** for bold
        .replace(/\*([^*]+)\*/g, '<i>$1</i>') // *text* for italic

    return <span dangerouslySetInnerHTML={{ __html: formattedText }} />
}


export function capitalizeUnderscoreStringWords(str) {
    // Split the string by underscores
    const words = str.split('_');

    // Capitalize the first letter of each word and join them back with a space
    const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());

    // Join the words back into a single string with spaces
    return capitalizedWords.join(' ');
}