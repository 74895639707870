// src/navItems.js

import { Home, Search, Logout, Quiz, Settings, Image, Person, Email, DataObjectSharp, DesktopAccessDisabledSharp, Storage, ViewAgenda, LocalPostOffice } from '@mui/icons-material';

export const navItems = [
    {
        value: 'Home',
        route: '',
        icon: <Home />
    },
    {
        value: 'Users',
        route: 'users',
        icon: <Person />
    },
    {
        value: 'Referred Users',
        route: 'referred-users',
        icon: <Person />
    },
    {
        value: 'Add Quiz',
        route: 'add-quiz-v2',
        icon: <Quiz />
    },
    {
        value: 'Quiz Management',
        route: 'exam-management',
        icon: <Settings />
    },
    {
        value: 'Image Upload',
        route: 'image-upload',
        icon: <Image />
    },
    {
        value: 'Search Question',
        route: 'search-question',
        icon: <Search />
    },
    {
        value: 'Bulk Email',
        route: 'bulk-email',
        icon: <Email />
    },
    {
        value: 'Static Data',
        route: 'static-data',
        icon: <ViewAgenda />
    },
    {
        value: 'Mail Logs',
        route: 'mail-logs',
        icon: <LocalPostOffice />
    },
];

export const logoutItem = {
    value: 'Logout',
    route: 'login',
    icon: <Logout />
};
