/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { BASE_URL } from '../../Api/Constants'
import { showTimeInAgoFormat } from '../../Utils/Time'
import { Autocomplete, Button, Snackbar, Stack, TextField } from '@mui/material'
import MuiAlert from '@mui/material/Alert'
import checkIcon from '../../Assets/checked.png'
import './TopicQuestions.css'
import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import { defaultModalStyle } from '../../Styles/Modal'
import { filterList } from '../../Utils/AutoComplete'
import DrawerNav from '../Navigation/DrawerNav'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

function TopicQuestions() {
    const location = useLocation()
    const navigate = useNavigate()
    const handleNavigation = (route) => {
        navigate(route)
    }
    const [open, setOpen] = React.useState(false)
    const [openEditModal, setOpenEditModal] = React.useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const handleOpenEditModal = (question) => {
        window.open(`/edit-question?id=${question._id}`, '_blank')
    }

    const handleCloseEditModal = () => {
        setQuestionData({
            _id: '',
            question: '',
            answer: '',
            options: []
        })
        setOpenEditModal(false)
    }

    const [contestData, setContestData] = useState({
        _id: '',
        name: '',
        description: '',
        startDate: '',
        questions: []
    })

    const handleDeleteQuestion = (id) => {
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ contestId: contestData._id, question: questionData.question, answer: questionData.answer, options: questionData.options })
        }

        fetch(`${BASE_URL}/api/admin/quiz/question/${id}`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Data: ', data)
                if (data.status === 200) {
                    setOpenSuccessAlert(true)
                    resetModalFields()
                    getTopicQuestions(currentTopicID)
                } else {
                    setOpenErrorAlert(true)
                }
            })
    }

    const handleEditTopic = (topic) => {
        navigate('/edit-topic?topic=' + topic)
    }

    const handleTopicDelete = () => {
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' }
        }

        fetch(`${BASE_URL}/api/quiz/topic/${currentTopicID}`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Data: ', data)
                if (data.status === 200) {
                    setOpenSuccessAlert(true)
                    navigate('/')
                } else {
                    setOpenErrorAlert(true)
                }
            })
    }

    const [currentTopicID, setCurrentTopicID] = useState('')
    const [topicName, setTopicName] = useState('')
    const [topicQuestions, setTopicQuestions] = useState([])
    const [apiCalled, setApiCalled] = useState(false)

    const handleTopicName = (topicList) => {
        try {
            setTopicName(topicList[0].topic)
            document.title = topicList[0].topic
        } catch {
            setTopicName('')
        }
    }

    const [questionData, setQuestionData] = useState({
        _id: '',
        question: '',
        correctOption: '',
        topic: '',
        type: '',
        imageURL: '',
        options: []
    })

    const getTopicQuestions = (topicID) => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        }

        fetch(`${BASE_URL}/api/quiz/topic/${topicID}`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                if (data.status === 200) {
                    setTopicQuestions(data.data.questions)
                    handleTopicName(data.data.questions)
                    setApiCalled(true)
                } else {
                    setOpenErrorAlert(true)
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
                setOpenErrorAlert(true)
            })
    }

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false)
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSuccessAlert(false)
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenErrorAlert(false)
    }

    const updateQuestionData = (property, value, index = null) => {
        setQuestionData((prevData) => {
            const updatedOptions = [...prevData.options]
            if (index !== null) {
                updatedOptions[index] = value
            } else {
                updatedOptions.push(value)
            }

            return {
                ...prevData,
                [property]: index !== null ? updatedOptions : value
            }
        })
        console.log('Options : ', questionData.options)
    }

    const renderOptions = () => {
        const existingOptions = questionData.options.length
        const remainingOptions = Math.max(10 - existingOptions, 0)
        const optionsArray = new Array(existingOptions + remainingOptions).fill('')

        return (
            <div className='flex flex-row gap-4 flex-wrap justify-center'>
                {optionsArray.map((_, index) => (
                    <TextField
                        key={index}
                        id={`outlined-controlled-option-${index}`}
                        label={`Option ${index + 1}`}
                        sx={{ width: '45%' }}
                        value={questionData.options[index] || ''}
                        onChange={(event) => {
                            updateQuestionData('options', event.target.value, index)
                        }}
                    />
                ))}
            </div>
        )
    }

    const resetModalFields = () => {
        setQuestionData({
            question: '',
            answer: '',
            options: []
        })
    }

    const getContestDetail = (contestId) => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        }

        fetch(`${BASE_URL}/api/admin/contest/${contestId}`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200) {
                    setContestData(data.data.contest)
                } else {
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
            })
    }

    const addQuestion = () => {
        handleClose()
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ contestId: contestData._id, question: questionData.question, answer: questionData.answer, options: questionData.options })
        }

        fetch(`${BASE_URL}/api/contest/question`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Data: ', data)
                if (data.status === 200) {
                    setOpenSuccessAlert(true)
                    resetModalFields()
                    getContestDetail(currentTopicID)
                } else {
                    setOpenErrorAlert(true)
                }
            })
    }

    const updateQuestion = () => {
        handleCloseEditModal()
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ type: questionData.type, imageURL: questionData.imageURL, topic: topicName, correctOption: questionData.correctOption, question: questionData.question, options: questionData.options })
        }

        fetch(`${BASE_URL}/api/admin/quiz/question/${questionData._id}`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Data: ', data)
                if (data.status === 200) {
                    setOpenSuccessAlert(true)
                    resetModalFields()
                    getTopicQuestions(currentTopicID)
                } else {
                    setOpenErrorAlert(true)
                }
            })
    }

    useEffect(() => {
        const path = location.pathname
        const topicId = path.substring(17)
        console.log('Contest Id : ', topicId)
        setCurrentTopicID(topicId)
        if (!apiCalled) {
            getTopicQuestions(topicId)
        }
    }, [apiCalled, location.pathname])

    return (
        <Box sx={{ display: 'flex' }}>
            <DrawerNav />
            <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                <div className='mt-4'>
                    <div className='text-3xl font-bold text-gray-700 my-4 text-center'>{topicName}</div>
                    {apiCalled && (
                        <div className='flex justify-center mb-4'>
                            <Button variant='contained' color='success' onClick={() => handleEditTopic(topicName)} sx={{ marginTop: '10px', cursor: 'pointer' }}>
                                Edit Topic
                            </Button>
                        </div>
                    )}
                    <div className='all__tickets__container'>
                        {topicQuestions.length > 0 &&
                            topicQuestions.map((topic) => {
                                return (
                                    <div class='tickets__card__container cursor-pointer'>
                                        <article class='each__ticket__container'>
                                            <div>
                                                <h2 className='font-bold'> {topic.question}</h2>
                                                <h2 className='mb-2'> {topic.description}</h2>
                                                <div class='text-xs text-gray-500'>
                                                    Updated {showTimeInAgoFormat(topic.updatedAt || topic.createdAt)} • Created {showTimeInAgoFormat(topic.createdAt)}
                                                </div>
                                                <div class='card-meta-snippets'></div>
                                                <Button
                                                    variant='contained'
                                                    onClick={() => handleOpenEditModal(topic)}
                                                    sx={{
                                                        marginTop: '10px',
                                                        marginRight: '10px',
                                                        cursor: 'pointer',
                                                        backgroundColor: 'darkgoldenrod',
                                                        '&:hover': {
                                                            backgroundColor: 'darkgoldenrod'
                                                        }
                                                    }}>
                                                    Edit
                                                </Button>
                                                <Button
                                                    variant='contained'
                                                    onClick={() => handleDeleteQuestion(topic._id)}
                                                    sx={{
                                                        marginTop: '10px',
                                                        marginRight: '10px',
                                                        cursor: 'pointer',
                                                        backgroundColor: 'sienna',
                                                        '&:hover': {
                                                            backgroundColor: 'sienna'
                                                        }
                                                    }}>
                                                    Delete
                                                </Button>
                                            </div>
                                        </article>
                                    </div>
                                )
                            })}

                        {topicQuestions.length === 0 && (
                            <>
                                <img className='h-20' src={checkIcon} alt='' />
                                <p className='text-2xl text-gray-500 mt-2'>No questions found</p>
                            </>
                        )}

                        {apiCalled && (
                            <div className='flex justify-center mb-8'>
                                <Button variant='contained' color='error' onClick={() => handleTopicDelete()} sx={{ marginTop: '10px', cursor: 'pointer' }}>
                                    Delete Topic
                                </Button>
                            </div>
                        )}
                    </div>
                    <Modal
                        aria-labelledby='transition-modal-title'
                        aria-describedby='transition-modal-description'
                        open={open}
                        onClose={handleClose}
                        closeAfterTransition
                        slots={{ backdrop: Backdrop }}
                        slotProps={{
                            backdrop: {
                                timeout: 500
                            }
                        }}>
                        <Fade in={open}>
                            <Box sx={defaultModalStyle}>
                                <div className='flex flex-col gap-6 justify-center'>
                                    <h1 className='text-center text-2xl font-bold text-gray-700'>Add Question</h1>
                                    <TextField
                                        id='outlined-controlled'
                                        label='Question'
                                        sx={{ width: '100%' }}
                                        value={questionData.question}
                                        onChange={(event) => {
                                            updateQuestionData('question', event.target.value)
                                        }}
                                    />
                                    <TextField
                                        id='outlined-controlled'
                                        label='Answer'
                                        sx={{ width: '100%' }}
                                        value={questionData.correctOption}
                                        onChange={(event) => {
                                            updateQuestionData('correctOption', event.target.value)
                                        }}
                                    />
                                    {renderOptions()}
                                    <Button variant='contained' color='success' onClick={() => addQuestion()} sx={{ width: '100%', cursor: 'pointer' }}>
                                        Add Question
                                    </Button>
                                </div>
                            </Box>
                        </Fade>
                    </Modal>
                    <Modal
                        aria-labelledby='transition-modal-title'
                        aria-describedby='transition-modal-description'
                        open={openEditModal}
                        onClose={handleCloseEditModal}
                        closeAfterTransition
                        slots={{ backdrop: Backdrop }}
                        slotProps={{
                            backdrop: {
                                timeout: 500
                            }
                        }}>
                        <Fade in={openEditModal}>
                            <Box sx={defaultModalStyle}>
                                <div className='flex flex-col gap-6 justify-center'>
                                    <h1 className='text-center text-2xl font-bold text-brown-700'>Edit Question</h1>
                                    <Autocomplete
                                        disablePortal
                                        id='combo-box-demo'
                                        value={questionData.type}
                                        options={filterList}
                                        onChange={(event, item) => {
                                            updateQuestionData('type', item.label)
                                        }}
                                        renderInput={(params) => <TextField {...params} label='Question Type' />}
                                    />
                                    {questionData.type === 'Image' && (
                                        <TextField
                                            disablePortal
                                            label='Image URL'
                                            id='combo-box-demo'
                                            value={questionData.imageURL}
                                            onChange={(event, item) => {
                                                updateQuestionData('imageURL', event.target.value)
                                            }}
                                        />
                                    )}
                                    <TextField
                                        id='outlined-controlled'
                                        label='Question'
                                        sx={{ width: '100%' }}
                                        value={questionData.question}
                                        onChange={(event) => {
                                            updateQuestionData('question', event.target.value)
                                        }}
                                    />
                                    <Autocomplete
                                        disablePortal
                                        id='combo-box-demo'
                                        value={questionData.correctOption}
                                        options={questionData.options.filter((option) => option !== undefined)}
                                        sx={{ width: '100%' }}
                                        onChange={(event, item) => {
                                            updateQuestionData('correctOption', item)
                                        }}
                                        renderInput={(params) => <TextField {...params} label='Correct Option' />}
                                    />

                                    <TextField
                                        id='outlined-controlled'
                                        label='Explanation'
                                        sx={{ width: '100%' }}
                                        value={questionData.explanation}
                                        onChange={(event) => {
                                            updateQuestionData('explanation', event.target.value)
                                        }}
                                    />
                                    {renderOptions()}
                                    <Button variant='contained' color='info' onClick={() => updateQuestion()} sx={{ width: '100%', cursor: 'pointer' }}>
                                        Update
                                    </Button>
                                </div>
                            </Box>
                        </Fade>
                    </Modal>

                    <Stack spacing={2} sx={{ width: '100%' }}>
                        <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                            <Alert onClose={closeSuccessAlert} severity='success' sx={{ width: '100%' }}>
                                Success!!!
                            </Alert>
                        </Snackbar>
                    </Stack>

                    <Stack spacing={2} sx={{ width: '100%' }}>
                        <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                            <Alert onClose={closeErrorAlert} severity='error' sx={{ width: '100%' }}>
                                Error!!!
                            </Alert>
                        </Snackbar>
                    </Stack>
                </div>
            </Box>
        </Box>
    )
}

export default TopicQuestions
