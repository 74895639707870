/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { BASE_URL } from '../../Api/Constants';
import { formatDateToYYYYMMDD } from '../../Utils/Time';
import { Box, Button, Card, Stack, Table, TableBody, TableContainer, TextField, Typography, TableCell, TableRow, TableHead, Paper } from '@mui/material';
import DrawerNav from '../Navigation/DrawerNav';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function SearchQuestion() {
    const [searchText, setSearchText] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const navigate = useNavigate();

    const handleSearch = async () => {
        try {
            const response = await fetch(`${BASE_URL}/api/v1/question-search?text=${searchText}`);
            if (response.ok) {
                const data = await response.json();
                console.log(' DATA : ', data);
                setSearchResults(data.data.questionsList);
            } else {
                throw new Error('Failed to fetch questions');
            }
        } catch (error) {
            console.error('Error fetching search results:', error);
        }
    };

    const { id } = useParams();
    const query = useQuery();
    const topicName = query.get('name');
    const subjectID = query.get('subjectID');
    const subjectName = query.get('subjectName');
    const [apiCalled, setApiCalled] = useState(false);
    const [examsList, setExamsList] = useState([]);

    const sectionsList = ['English', 'Grammar', 'English Exam', 'English Verbs'];

    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccessAlert(false);
    };
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenErrorAlert(false);
    };

    const [searchQuery, setSearchQuery] = useState('');
    const [filteredTopics, setFilteredTopics] = useState([]);

    useEffect(() => {
        if (searchQuery === '') {
            setFilteredTopics(examsList);
        } else {
            setFilteredTopics(
                examsList.filter(
                    (topic) =>
                        topic.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        (topic.description && topic.description.toLowerCase().includes(searchQuery.toLowerCase()))
                )
            );
        }
    }, [searchQuery, examsList]);

    const handleEditQuestion = (id) => {
        navigate('/edit-question?id=' + id);
    };

    const handleDeleteQuestion = (id, question) => {
        navigate('/delete-question?id=' + id + `&question=${question}`);
    };

    return (
        <>
            <Box sx={{ display: 'flex' }}>
                <DrawerNav />
                <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
                    <div className='pb-48'>
                        <Stack direction='row' alignItems='center' justifyContent='space-between' mb={5}>
                            <Typography variant='h4' sx={{ fontWeight: 500 }}>
                                Search Question
                            </Typography>
                            <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                                <TextField
                                    label='Search'
                                    sx={{ mr: 4, width: '400px' }}
                                    value={searchText}
                                    onChange={(e) => setSearchText(e.target.value)}
                                    variant='outlined'
                                />
                                <Button variant='contained' size='large' onClick={handleSearch}>
                                    Search
                                </Button>
                            </Stack>
                        </Stack>

                        <Card>
                            <TableContainer component={Paper} sx={{ overflow: 'unset' }}>
                                <Table sx={{ minWidth: 650 }}>
                                    <TableHead sx={{ backgroundColor: '#262726' }}>
                                        <TableRow>
                                            <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>Question</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>Answer</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>Edit</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>Delete</TableCell>
                                            <TableCell sx={{ fontWeight: 'bold', color: 'white' }}>Added</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {searchResults.length > 0 ? (
                                            searchResults.map((row, index) => (
                                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                    <TableCell sx={{ width: '30%' }}>{row.question}</TableCell>
                                                    <TableCell>{row.correctOption}</TableCell>
                                                    <TableCell>
                                                        <Button variant='contained' color='primary' size='small' onClick={() => handleEditQuestion(row._id)}>
                                                            Edit
                                                        </Button>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Button variant='contained' color='error' size='small' onClick={() => handleDeleteQuestion(row._id, row.question)}>
                                                            Delete
                                                        </Button>
                                                    </TableCell>
                                                    <TableCell>{formatDateToYYYYMMDD(row.createdAt)}</TableCell>
                                                </TableRow>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell colSpan={5} align='center'>
                                                    No questions found
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Card>
                    </div>
                </Box>
            </Box>
        </>
    );
}

export default SearchQuestion;
